<template>
  <section class="modal--container auth--container forget--pass-container">
    <div class="top--image">
      <img src="@/assets/img/icons/mail-2.svg" />
    </div>
    <div class="title--text">
      {{ $t('loginRegister.forgot') }}
    </div>
    <div class="desc">
      {{ $t('loginRegister.forgotMsg') }}
    </div>
    <hr class="separator" />
    <div class="btn btn-primary main--btn" @click="close">
      {{ $t('general.ok') }}
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    close() {
      this.$modal.hide('modal-forget-pass-confirm');
    },
  },
};
</script>
