<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <forget-pass-confirm></forget-pass-confirm>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ForgetPassConfirm from '@/components/auth/forget-pass-confirm';
export default {
  components: {
    ForgetPassConfirm,
  },
  data: () => ({
    modalName: 'modal-forget-pass-confirm',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
